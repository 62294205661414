import base from './baseApi';
import { queryFilter } from 'utils/proptypes';
import { createSearchParams } from 'react-router-dom';
import { omitBy } from 'lodash';

const { urls, methods, execute } = base();

const createIntercomContact = (payload: any) => {
    const method = methods.POST;
    let url = `${urls.intercom.contact}`;
    const response = execute(method, url, payload);
    return response;
};

const getIntercomContact = (id: string) => {
    const method = methods.GET;
    let url = `${urls.intercom.contact}/${id}`;
    const response = execute(method, url);
    return response;
};

const generateHmac = (payload: any) => {
    const method = methods.POST;
    let url = `${urls.intercom.generateHmac}`;
    const response = execute(method, url, payload);
    return response;
};

export { createIntercomContact, getIntercomContact, generateHmac };
