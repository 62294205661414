
import PaginateTable from 'components/table/paginate';
import { HiOutlinePencil, HiOutlineTrash } from 'react-icons/hi';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { apiStatus, pageCount, routePaths } from 'utils/constants';
import { isEmpty } from 'lodash';
import NoResult from 'components/commonComponent/noResult';
import { useSelector } from 'react-redux';
import ActionTable from 'components/table/actionTable';
import { orgSelector } from 'redux/selectors';
import Table from 'components/table/table';
import { useNavigate } from 'react-router-dom';
import Actions from 'components/actions';
import SpinnerComponent from 'components/spinner';
import ListEmpty from 'components/empty/ListEmpty';
import Svg from 'components/svg';
import { toast } from 'react-toastify';
import { deleteOrganizationExerciseTemplate, getOrganizationExerciseTemplates } from 'api/organizationExerciseTemplateApi';
import { isImageType, isVideoType } from 'utils/utils';
import ReactPlayer from "react-player";
import OpenFileModal from '../therapists/patient/openFileModal';
import { useForm } from 'react-hook-form';

const OrganizationExerciseTemplateList = (props: any) => {
    const queryClient = useQueryClient();
    const [t] = useTranslation();
    const navigate = useNavigate();
    const { organizationId } = useSelector(orgSelector);
    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [totalEntities, setTotalEntities] = useState(0);
    const [exerciseTemplates, setExerciseTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState<any>(null)
    const [openFile, setOpenFile] = useState(false)

    const hookForm = useForm({
        mode: "onChange",
        defaultValues: {
            exercises: [selectedTemplate]
        }
    });
    const {
        formState: { errors },
        reset
    } = hookForm

    const handleAddOrganizationExerciseTemplate = () => {
        navigate(routePaths.ORG_ADD_EXERCISE_TEMPLATES)
    };

    const { data, isLoading } = useQuery(
        ['getOrganizationExerciseTemplates', currentPage, searchValue, organizationId],
        () =>
            getOrganizationExerciseTemplates({
                page: currentPage,
                limit: pageCount,
                searchQuery: searchValue,
            }),
        {
            onSuccess: (data: any) => {
                setTotalEntities(data.totalEntities);
            },
            onError: () => setExerciseTemplates([]),
            staleTime: Infinity,
        },
    );

    const handleSearch = (value: string) => {
        setSearchValue(value);
        setCurrentPage(0);
    };

    useEffect(() => {
        if (data !== undefined) {
            setExerciseTemplates(data?.data?.entities);
            setTotalEntities(data?.data.totalEntities);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        setCurrentPage(0)
    }, [organizationId])

    if (!isLoading && isEmpty(searchValue) && totalEntities === 0) {
        return (
            <div className="w-full sm:p-8 p-4">
                <ListEmpty
                    icon={
                        <Svg name='exercise-template-empty' className='h-[84px] w-auto' />
                    }
                    buttonName={t('Add Exercise')}
                    handleAddClick={handleAddOrganizationExerciseTemplate}
                    note={t('Please ADD a Exercise Library and it will show up here')}
                    title={t('This does not have any Exercise Library yet')}
                />
            </div>
        );
    }

    const handleEditOrganizationExerciseTemplate = (exerciseTemplateId: string) => {
        navigate(`${routePaths.ORG_ADD_EXERCISE_TEMPLATES}?exerciseTemplateId=${exerciseTemplateId}`);
    }

    const handleRemoveOrganizationExerciseTemplate = async (exerciseTemplateId: string) => {
        try {
            const data = await deleteOrganizationExerciseTemplate(exerciseTemplateId);
            if (data.status === apiStatus.SUCCESS) {
                const message: string = t('Deleted Exercise Template successfully');
                toast.success(message);
                queryClient.invalidateQueries('getOrganizationExerciseTemplates', { refetchActive: true }, { cancelRefetch: true });
            }
        } catch (e) {
            const message: string = t('generalErrors');
            toast.error(message);
        }
    }

    const handleViewOrganizationExercise = (item: any) => {
        setOpenFile(true)
        setSelectedTemplate(item)
        reset(item)
    }

    return (
        <div className="w-full h-full sm:px-8 px-4">
            <div className='md:py-5'>
                <ActionTable
                    placeholderSearch={t('Search')}
                    buttonName={t('Add Exercise')}
                    handleAddClick={handleAddOrganizationExerciseTemplate}
                    handleSearch={handleSearch}
                />
            </div>
            {isLoading && <SpinnerComponent />}
            {totalEntities === 0 && !isLoading && <NoResult />}
            {totalEntities > 0 && (
                <>
                    <div className="overflow-x-auto mb-2 list-table">
                        <Table>
                            <Table.Head>
                                <Table.HeadCell className="cursor-pointer">
                                    <div className="flex items-center">{t('Exercise Name')}</div>
                                </Table.HeadCell>
                                <Table.HeadCell className="hidden-mobile">
                                    <div className="flex items-center">{t('Video/Image')}</div>
                                </Table.HeadCell>
                                <Table.HeadCell className="hidden-mobile-tablet">
                                    <div className="flex items-center">{t('Description/Tips')}</div>
                                </Table.HeadCell>
                                <Table.HeadCell scope="col" className="py-2 w-6" />
                            </Table.Head>
                            <Table.Body>
                                {exerciseTemplates.map((item: any) => (
                                    <Table.Row key={`row-${item.id}`} className="bg-white hover:bg-bg-hover text-base hover:border-b text-on-primary-container">
                                        <Table.Cell className="text-sm py-2.5 " onClick={() => handleEditOrganizationExerciseTemplate(item?.id)}>
                                            {item.name}
                                        </Table.Cell>
                                        <Table.Cell className="text-sm hidden-mobile py-2.5 " onClick={() => handleViewOrganizationExercise(item)}>
                                            {(item?.videoUrl || (item?.file?.url && isVideoType(item?.file?.type))) && (
                                                <div className='relative w-full rounded-lg bg-black' style={{ width: 80, maxWidth: 80, maxHeight: 60 }}>
                                                   {item?.videoUrl ?  <span className="absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center focus:outline-none focus:ring-4 focus:ring-gray-200">
                                                        <Svg name='youtube-play-icon' className='w-[25px] h-[25px]' />
                                                    </span> : <span className="absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center focus:outline-none focus:ring-4 focus:ring-gray-200">
                                                        <Svg name='play-icon' className='w-[25px] h-25px]' />
                                                    </span> }
                                                    {item?.file?.thumbnail &&
                                                      <div className="rounded-lg border border-gray-300 relative"
                                                           style={{ width: 80, height: 60 }}>
                                                          <span
                                                            className="absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center focus:outline-none focus:ring-4 focus:ring-gray-200">
                                                        <Svg name="play-icon" className="w-[25px] h-25px]" />
                                                    </span>
                                                          <img className="preview object-cover rounded"
                                                               style={{ width: 80, height: 60 }}
                                                               src={item?.file?.thumbnail}
                                                               alt="avatar" />

                                                      </div>}
                                                    {!item?.file?.thumbnail && <ReactPlayer
                                                      controls={false}
                                                      playIcon={<button className="hidden">{''}</button>}
                                                      playing={false}
                                                      className="react-player rounded-lg"
                                                        width={80}
                                                        height={60}
                                                        url={item?.videoUrl ? item?.videoUrl : item?.file?.url}
                                                        light={!!item?.videoUrl}
                                                        style={{ borderRadius: 8 }}
                                                    />}
                                                </div>
                                            )}

                                            {item?.file?.url && isImageType(item?.file?.type) &&
                                                (
                                                    <div className="rounded-lg border border-gray-300 relative" style={{ width: 80, height: 60 }}>
                                                        <img className="preview object-cover rounded" style={{ width: 80, height: 60 }} src={item?.file?.url} alt="avatar" />
                                                    </div>
                                                )}
                                             {!item?.file?.url && !item?.videoUrl &&
                                                (
                                                    <div className="rounded-lg flex justify-center items-center border relative bg-[#EDEDED]" style={{ width: 80, height: 60 }}>
                                                        <Svg name='empty-image' className='w-[25px] h-25px]' />
                                                    </div>
                                                )}
                                        </Table.Cell>
                                        <Table.Cell className="text-sm hidden-mobile-tablet py-2.5 whitespace-pre-line">
                                            {item.description}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Actions>
                                                <Actions.Item
                                                    show
                                                    action={() => handleEditOrganizationExerciseTemplate(item?.id)}
                                                    icon={<HiOutlinePencil className="w-5 h-5  text-on-primary-container" strokeWidth={2.5} />}
                                                />
                                                <Actions.Item
                                                    show
                                                    action={() => handleRemoveOrganizationExerciseTemplate(item?.id)}
                                                    icon={<HiOutlineTrash className="w-5 h-5  text-on-primary-container" strokeWidth={2.5} />}
                                                />
                                            </Actions>
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                            {isLoading && <SpinnerComponent />}
                        </Table>
                    </div>
                    <PaginateTable setCurrentPage={setCurrentPage} currentPage={currentPage} totalEntities={totalEntities} />
                    <OpenFileModal isTemplate hookForm={hookForm} hasVideoUrl={!!selectedTemplate?.videoUrl} isVideo={isVideoType(selectedTemplate?.file?.type) || !!selectedTemplate?.videoUrl} fileUrl={selectedTemplate?.file?.url || selectedTemplate?.videoUrl} openModal={openFile} setOpenModal={setOpenFile} />

                </>
            )}
        </div>
    );
};

export default OrganizationExerciseTemplateList;
