/* eslint-disable react/jsx-props-no-spreading */
import { Modal } from 'flowbite-react';
import { useRef, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import 'styles/styles.scss';
import './confirmDeleteModal.scss'
import { Button } from 'flowbite-react';
import { chargeSubscription } from 'api/stripeApi';
import './subcribeModal.scss'
import { useNavigate } from 'react-router-dom';
import { roleName, routePaths, STRIPE_FIRST_USER_PRICE, STRIPE_NEXT_USER_PRICE } from 'utils/constants';
import useUser from 'hooks/useUser';
import { useQuery } from 'react-query';
import { getOrganizationUsers } from '../../api/userApi';
import { useSelector } from 'react-redux';
import { orgSelector } from '../../redux/selectors';

const SubcribeModal = (props: any) => {
    const { openModal, setOpenModal, handleRemove } = props
    const rootRef = useRef<HTMLDivElement>(null);
    const [t] = useTranslation()
    const { organizationId } = useSelector(orgSelector);
    const [totalEntities, setTotalEntities] = useState(0);

    const navigate = useNavigate()
      const { fetchCurrentUser } = useUser();

    const closeModal = () => {
        setOpenModal(false)
    };

    useQuery(
      ['getOrganizationTherapists', organizationId],
      () =>
        getOrganizationUsers({
            page: 1,
            limit: 1000,
            roleNames: `${roleName.ORGANIZATION_ADMIN},${roleName.THERAPIST}`,
        }),
      {
          onSuccess: (data: any) => {
              setTotalEntities(data?.data?.totalEntities);
          },
          onError: () => {},
      },
    );

    const getPrice = () => {
        if(totalEntities < 2) {
            return STRIPE_FIRST_USER_PRICE;
        } else {
            return STRIPE_FIRST_USER_PRICE + (totalEntities - 1) * STRIPE_NEXT_USER_PRICE;
        }
    }

    const handleSubscription = async () => {
        try {
            const result = await chargeSubscription();
            if (result.status === 201 && result?.data?.url) window.location.replace(result.data.url);
            if (result.status === 201) {
                  navigate(routePaths.DASHBOARD_PAGE);
                  fetchCurrentUser()
            } else {
                const message: string = t('subscription.createdFail');
            }
        } catch (e) {
            const message: string = t('subscription.createdFail');
        }
    };
    return (
      <div ref={rootRef}>
        <Modal show={openModal} size="xl" root={rootRef.current ?? undefined} dismissible={true} className="modal-subcribe z-10">
          <Modal.Body className="modal-body p-6 md:p-5 lg:p-[50px]">
            <div className="flex justify-center">
              <div className="border flex justity-center items-center border-primary w-[104px] h-[36px]">
                <p className="text-header w-full text-center uppercase text-primary font-bold text-base">{t('monthly')}</p>
              </div>
            </div>
            <p className="text-header w-full text-center font-medium text-[16px] md:text-[15px] lg:text-[20px] pt-4 md:pt-1 lg:pt-4">
              {t('Total monthly when add Therapist/OrgAdmin')}
            </p>
            <div className="flex justify-center">
              <div className="">
                <span className="text-header font-bold text-[40px] md:text-[30px] lg:text-[70px]">${getPrice()}</span>
                <span className="text-base font-medium">/{t('month')}</span>
              </div>
            </div>
            <p className="text-center text-sm">{t('Organization Billing that OrgAdmin can view/manage')}</p>
            <p className="text-center text-sm">
              <Trans i18nkey="cancelSubscription">{t('cancelSubscription')}</Trans>
            </p>
            <div className="flex justify-center items-center py-3 lg:py-5">
              <Button
                className="subcribe-button bg-primary hover:bg-primary w-[200px] h-[44px] flex justify-center items-center text-center text-[14px]"
                type="button"
                size="lg"
                onClick={() => {
                  const fetchAsync = async () => {
                    await handleSubscription();
                  };
                  fetchAsync().catch(() => {});
                }}
              >
                {t('Subcribe Now')}
              </Button>
            </div>
            <p className="text-center text-xs">
              {t('First Therapist/OrgAdmin')}
              <span className="text-base text-xs font-bold">
                {STRIPE_FIRST_USER_PRICE} USD
              </span>
              <span className="text-base text-xs">
                /{t('month')}
              </span>
            </p>
            <p className="text-center text-xs">
              <Trans i18nkey="payForTherapist">{t('payForTherapist')}</Trans>
            </p>
          </Modal.Body>
        </Modal>
      </div>
    );
}
export default SubcribeModal