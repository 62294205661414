import { getExercises, getExercisesChart } from "api/exerciseApi";
import Calendar from "components/weekCalendar"
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { userSelector } from "redux/selectors";
import { messageErrors } from 'utils/utils';
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
import ExerciseItem from "./exerciseItem";
import Svg from "components/svg";
import ListEmpty from "components/empty/ListEmpty";
import { Bar, Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, BarElement } from 'chart.js';
import { setDate } from "date-fns";
import { Progress } from "flowbite-react";
import 'styles/styles.scss'
import './dashboard.scss'
import { groupBy } from "lodash";
import SpinnerComponent from '../../components/spinner';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);

const PatientDashboard = (props: any) => {
    const { patient = null, isTherapistSite = false } = props
    const [selectedDate, setSelectedDate] = useState(new Date());
    const { userInfo } = useSelector(userSelector);
    const [t] = useTranslation();
    const [exercises, setExercises] = useState<any>([])
    const [labels, setLabels] = useState([])
    const [dataChart, setDataChart] = useState([])
    const [todayPercent, setTodayPercent] = useState(0)
    const patientId = isTherapistSite ? patient.id : userInfo.id
    const [isLoading, setIsLoading] = useState(false);
    const [expandAll, setExpandAll]: any = useState([]);
    const [expandAllArchived, setExpandAllArchived]: any = useState([]);
    const [isExpandAll, setIsExpandAll]: any = useState(false);
    const [expands, setExpands]: any = useState([]);

    const { data: dataExercises, isLoading: isLoadingExerciseOfPatient } = useQuery(['getExerciseOfPatient', selectedDate, patientId], () => getExercises({
        patientId: patientId,
        page: 0,
        limit: 0,
        date: moment(selectedDate).format('YYYY-MM-DD'),
        includeArchived: isTherapistSite ? true : false,
    }), {
        retry: false,
        staleTime: Infinity,
        onError: error => {
            const message: string = messageErrors(error, t);
            toast.error(message);
        },
    });

    const { data: dataExercisesToday } = useQuery(['getExerciseToday', patientId], () => getExercises({
        patientId: patientId,
        page: 0,
        limit: 0,
        date: moment(new Date()).format('YYYY-MM-DD'),
        includeArchived: false,

    }), {
        retry: false,
        staleTime: Infinity,
        onError: error => {
            const message: string = messageErrors(error, t);
            toast.error(message);
        },
        onSuccess: (data) => {
            const completedExercise = data.data.entities?.filter((ex: any) => ex.timesPerDay === ex.exerciseCompletion.length).length
            const percentComplete = Math.round(!!data?.data?.totalEntities ? completedExercise / data?.data?.totalEntities * 100 : 0)
            setTodayPercent(percentComplete)
        }
    });


    const { data: exerciseChart } = useQuery(['getExerciseChart', selectedDate, patientId], () => getExercisesChart({
        patientId: patientId,
        page: 0,
        limit: 0,
        date: moment(selectedDate).format('YYYY-MM-DD'),
        includeArchived: false,
    }), {
        retry: false,
        staleTime: Infinity,
        onError: error => {
            const message: string = messageErrors(error, t);
            toast.error(message);
        },
    });

    useEffect(() => {
        if (dataExercises !== undefined) {
            const exerciseByProgram = Object.values(groupBy(dataExercises.data.entities, ({ program }) => program.id)).sort((a: any, b: any) => a[0].program.created - b[0].program.created)
            setExercises(exerciseByProgram)
        }
    }, [dataExercises])

    useEffect(() => {
        if (exerciseChart !== undefined) {
            const tempLabels = exerciseChart.data.map((item: any) => moment(item.date).utc().format('MMM D'))
            setLabels(tempLabels)
            const tempData = exerciseChart.data.map((item: any) => item.percent)
            setDataChart(tempData)
        }
    }, [exerciseChart])


    const dataSet = {
        labels: labels,
        datasets: [
            {
                backgroundColor: 'rgba(57, 109, 124, 1)',
                borderWidth: 0,
                fill: false,
                borderColor: 'rgba(54, 162, 235, 0)',
                data: dataChart,
                // barThickness: 24
            },
        ],
    };

    const options: any = {
        indexAxis: 'x',
        plugins: {
            legend: {
                display: false, // Hide the legend
            },

        },

        scales: {
            offset: false,
            xAxes: [{
                ticks: {
                    beginAtZero: true, // Đảm bảo giá trị đầu tiên trên trục X bắt đầu từ 0
                },
            }],
            yAxes: [{
                ticks: {
                    beginAtZero: true, // Đảm bảo giá trị đầu tiên trên trục X bắt đầu từ 0
                },
            }],
            x: {
                grid: {
                    display: false, // Hide the y-axis border
                    offset: false,
                },
                ticks: {

                }
            },
            y: {
                barThickness: '24px',
                padding: 0,
                barPercentage: 0.5,
                beginAtZero: true,
                max: 100,
                ticks: {
                    callback: (value: any) => {
                        return value.toString() + '%'; // Append '%' to the value
                    },
                    stepSize: 20,
                },
                grid: {
                    borderDash: ['5px', '5px'], // Set border dash style [dash length, gap length]
                    offset: false,
                },
            },

        },
        layout: {
            padding: {
                left: 0, // Set left padding to 0 to move bars adjacent to the y-axis
                right: 0
            },
        },
        barPercentage: 0.6, // Adjust the width of the bars to fill the available space
        categoryPercentage: 0.8,
        align: 'start'

    };

    const handleChangeTab = (isExpand: boolean) => {
        if (!isExpand) {
            setExpands([]);
        }
        setIsExpandAll(isExpand);
    };


  const handleExpandExercise = (index: any) => {
    setExpands({
      ...expands,
      [index]: !expands?.[index],
    });
    setExpandAll({ ...expandAll, [index]: !expandAll?.[index] });
  };
  
  console.log("expandAll", expandAll);
  
    useEffect(() => {
        if (isExpandAll) {
            console.log("exercises", exercises);
            
            const expandExercises = exercises.reduce((acc: any, _:any, index: any) => {
                console.log("accacc", acc);
                
                acc[index] = true;
                return acc;
            }, {});
            console.log("expandExercises", expandExercises);
            
            setExpandAll(expandExercises);
            setExpands([]);
        } else {
            setExpandAll([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExpandAll]);
    return (
        <div className="dashboard px-0 md:px-5 flex flex-col h-full">
            <div className={`${isTherapistSite ? 'pb-6 pt-2' : 'py-0 lg:py-8'}`}>
                <Calendar selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
            </div>
            <div className="flex dashboard-container grow w-full">
                {(isLoading || isLoadingExerciseOfPatient) && <SpinnerComponent />}
                <div className="flex flex-col w-3/5 exercises-container overflow-auto pb-2">
                    <div className=" flex justify-center pb-[10px]">
                        <div className="w-fit flex bg-[#EDEDED] p-[2px]  rounded-[6px] gap-2 border border-[2px] border-[#EDEDED]">
                            <div className={`uppercase cursor-pointer text-sm py-[5px] px-[15px] font-semibold rounded-[6px] ${!isExpandAll ? 'bg-tertiary text-white' : 'bg-[EDEDED] text-tertiary'}`} onClick={() => handleChangeTab(false)}>
                                {t('Condensed View')}
                            </div>
                            <div className={`uppercase cursor-pointer text-sm py-[5px] px-[15px] font-semibold rounded-[6px] ${isExpandAll ? 'bg-tertiary text-white' : 'bg-[EDEDED] text-tertiary'}`} onClick={() => handleChangeTab(true)}>
                                {t('Expanded View')}
                            </div>
                        </div>
                    </div>
                    {exercises.length > 0 ? (exercises.map((programExercises: any, index: number) =>
                    (
                        <>
                            <div className="background-hover py-3.5 rounded-t-lg  px-3 w-fit uppercase text-secondary font-semibold text-sm text-header">{programExercises[0].program?.name}</div>
                            <div className="background-hover md:px-4 py-4 md:py-4 lg:py-4 mb-3 rounded-r-lg rounded-b-lg">
                                <div className="flex flex-col gap-4">
                                    {programExercises.map((item: any, index: number) =>
                                        <ExerciseItem 
                                            expand={expands[index]}
                                            expandAll={expandAll[index]}
                                            isExpandAll={isExpandAll}
                                            isTherapistSite={isTherapistSite}
                                            exercise={item}
                                            index={index}
                                            selectedDate={selectedDate}
                                            setIsLoading={setIsLoading}
                                            isLoading={isLoading} 
                                            handleExpandExercise={handleExpandExercise}
                                            />
                                    )}
                                </div>
                            </div>

                        </>)

                    )) : (

                        <div className="w-full">
                            <ListEmpty
                                title={t('titleExerciseEmpty')}
                                note={t('noteExerciseEmpty')}
                                icon={<Svg name='book' className='h-[84px] w-auto' />}
                            />
                        </div>
                    )}
                </div>
                <div className="w-2/5 p-10 ml-10 chart-container h-fit background-hover">
                    <div className="font-semibold text-lg text-header">
                        {t('progress')}
                    </div>
                    <div className="mt-5 mb-3 flex w-full items-center">
                        <div className="pr-2 text-sm">
                            {t('today')}
                        </div>
                        <div className="grow" >
                            <Progress progress={todayPercent} size="lg" className="progress-bar" labelProgress />
                        </div>
                    </div>
                    <div className="mt-10">
                        <Bar
                            data={dataSet}
                            options={options}
                        />
                    </div>

                </div>
            </div>
        </div>

    )
}

export default PatientDashboard