/* eslint-disable */

import { useTranslation } from 'react-i18next'
import './exerciseItemPdf.scss'
import { getYoutubeId, isImageType, isVideoType, toHHMMSS } from 'utils/utils';
import Svg from 'components/svg';
import { useState } from 'react';

const ExerciseItemPdf = (props: any) => {
    const { exercise } = props
    const [t] = useTranslation()
      const [orientation, setOrientation] = useState("");

 const checkOrientation = (img:any) => {
    if (img.naturalWidth > img.naturalHeight) {
      setOrientation("Horizontal");
    } else if (img.naturalWidth < img.naturalHeight) {
      setOrientation("Vertical");
    } else {
      setOrientation("Square");
    }
  };
    return (
        <div className="exercise-pdf-item p-[10px] border-t border-white">
            <div className=' shadow-md exercise-content flex flex-row' >
            <div className='w-[186px] min-w-[200px] '>
                {(exercise?.videoUrl || exercise?.file?.url) && (
                    <div className='w-[186px] flex justify-center '>
                        {exercise?.videoUrl && !exercise?.file?.url && (
                            <div className='w-[186px] aspect-video'>
                                <div className='w-[186px] relative aspect-video'>
                                    <span className="absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center focus:outline-none focus:ring-4 focus:ring-gray-200">
                                        <Svg name='youtube-play-icon' className='w-[30px] h-[30px]' />
                                    </span>
                                    <div className='w-full h-full'>
                                        <img className="preview w-full h-full object-cover" src={`https://img.youtube.com/vi/${getYoutubeId(exercise?.videoUrl)}/hqdefault.jpg`} alt="avatar" />
                                    </div>
                                </div>
                            </div>
                        )}
                        {!exercise?.videoUrl && exercise?.file?.url && isVideoType(exercise?.file?.type) && (
                            <div className='w-[186px] relative aspect-video'>
                                <span className="absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center focus:outline-none focus:ring-4 focus:ring-gray-200">
                                    <Svg name='play-icon' className='w-[30px] h-[30px]' />
                                </span>
                                <div className='w-full bg-black aspect-video flex justify-center'>
                                    <video className="preview w-auto h-full object-cover" src={exercise?.file?.url} />
                                </div>
                            </div>
                        )}
                        {!exercise?.videoUrl && exercise?.file?.url && isImageType(exercise?.file?.type) && (
                            <div className='w-[186px] relative aspect-video'>
                              <div className='w-full bg-black aspect-video flex justify-center'>
                                <img className={`preview  h-auto ${orientation === 'Horizontal' ? 'w-full object-cover': 'w-auto'}`} src={exercise?.file?.url} alt="avatar" onLoad={(e) => checkOrientation(e.target)}/>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {(!exercise?.videoUrl && !exercise?.file?.url) && (
                    <div className='w-[186px] relative aspect-video bg-[#EDEDED] flex justify-center items-center'>
                                    <Svg name='hedge-pdf-logo' className='w-[30px] h-[30px]' />
                    </div>
                ) }
                    </div>
                <div className='pb-2 grow whitespace-pre-line'>
                    <div className=' flex bg-lightTertiary exercise-header p-2 flex-col'>
                        <div className='text-base font-medium text-secondary'>{exercise.name}</div>
                        <div className='flex gap-1'>
                            {!isNaN(parseInt(exercise?.sets)) && parseInt(exercise?.sets) > 0 && <div className='p-1 bg-white border rounded-md text-10 text-tertiary'>{exercise?.sets || 0} {t('Sets')}</div>}
                            {!isNaN(parseInt(exercise?.reps)) && parseInt(exercise?.reps) > 0 && <div className='p-1 bg-white border rounded-md text-10 text-tertiary'>{exercise?.reps || 0} {t('Reps')}</div>}
                            {!isNaN(parseInt(exercise?.duration)) && parseInt(exercise?.duration) > 0 && <div className='p-1 bg-white border rounded-md text-10 text-tertiary'>{t('Duration')}: {toHHMMSS(exercise?.duration) || 0} </div>}
                            <div className='p-1 bg-white border rounded-md text-10 text-tertiary'>{t('Times per Day')}: {exercise?.timesPerDay || 0} </div>
                            {exercise.isMonday && <div className='p-1 bg-tertiary border rounded-md text-10 text-white font-semibold'>MO </div>}
                            {exercise.isTuesday && <div className='p-1 bg-tertiary border rounded-md text-10 text-white font-semibold'>TU </div>}
                            {exercise.isWednesday && <div className='p-1 bg-tertiary border rounded-md text-10 text-white font-semibold'>WE </div>}
                            {exercise.isThursday && <div className='p-1 bg-tertiary border rounded-md text-10 text-white font-semibold'>TH </div>}
                            {exercise.isFriday && <div className='p-1 bg-tertiary border rounded-md text-10 text-white font-semibold'>FR </div>}
                            {exercise.isSaturday && <div className='p-1 bg-tertiary border rounded-md text-10 text-white font-semibold'>SA </div>}
                            {exercise.isSunday && <div className='p-1 bg-tertiary border rounded-md text-10 text-white font-semibold'>SU </div>}
                        </div>
                    </div>
                    <div className='pt-2 px-2 text-[12px] leading-[14px]'>
                        {exercise?.description}
                    </div>
                     <div className="w-full px-2 flex break-words">
                        <a href={exercise?.videoUrl} rel="noreferrer" className="text-[12px] leading-[14px] break-all text-tertiary text-center underline" target="_blank">{exercise?.videoUrl}</a>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default ExerciseItemPdf