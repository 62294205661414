/* eslint-disable react/jsx-props-no-spreading */

import Button from "components/button/button";
import ButtonBack from "components/button/buttonBack"
import InputText from "components/inputText";
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FieldValues, useForm } from 'react-hook-form';
import { routePaths } from "utils/constants";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { isVideoType, messageErrors } from 'utils/utils';
import { useNavigate, useSearchParams } from "react-router-dom";

import SpinnerComponent from "components/spinner";
import { createExerciseTemplate, getExerciseTemplateById, updateExerciseTemplate } from "api/exerciseTemplateApi";
import { uploadFile } from "api/exerciseApi";
import { IMAGE_TYPE_REGEX } from "utils/constants";
import FileUpload from "../patient/fileUpload";
import './addExerciseTemplate.scss'
import { trim } from "lodash";
import DiscardSaveModal from '../../../components/modal/discardSaveModal';
import { useDispatch, useSelector } from 'react-redux';
import { uploadSelector } from '../../../redux/selectors';
import { uploadAction } from '../../../redux/actions';
import AlertUploadingModal from '../../../components/modal/alertUploadingModal';

const AddExerciseTemplate = (props: any) => {
    const { isTemplate = false } = props
    const [disableVideoUrl, setDisableVideoUrl] = useState(false)
    const dispatch = useDispatch();
    const {uploading} = useSelector(uploadSelector)
    const [t] = useTranslation()
    const [isUploadingFile, setIsUploadingFile] = useState(false)
    const [searchParams] = useSearchParams();
    const [fileUrl, setFileUrl] = useState<any>('');
    const [file, setFile]: any = useState();
    const queryClient = useQueryClient();
    const navigate = useNavigate()
    const [isVideo, setIsVideo] = useState(false)
    const [hasVideoUrl, setHasVideoUrl] = useState(false)
    const [openDiscardSaveModal, setOpenDiscardSaveModal] = useState(false)
    const [openAlertUploadingModal, setOpenAlertUploadingModal] = useState(false)

    const exerciseTemplateId: any = searchParams.get('exerciseTemplateId') || null

    const { data: exerciseTemplate } = useQuery(
        ['getExerciseTemplateById', exerciseTemplateId],
        () =>
            getExerciseTemplateById(exerciseTemplateId),
        {

            onError: (error) => {
                const message: string = messageErrors(error, t);
                toast.error(message);
            },
            enabled: exerciseTemplateId !== null,
            staleTime: Infinity,
        },
    );
    const ValidateSchema = Yup.object().shape({
        name: Yup.string().required(t('userManagementPage.requiredField')),
        description: Yup.string().nullable(),
        fileId: Yup.string().nullable(),
        videoUrl: Yup.string().nullable()
    });


    const hookForm = useForm({
        mode: "onChange",
        resolver: yupResolver(ValidateSchema),
    });

    const {
        formState: { errors, isDirty },
        handleSubmit,
        reset,
        getValues,
        setError,
    } = hookForm


    const handleMutationProgramTemplate = (payload: any) => {
        return !!exerciseTemplateId ? updateExerciseTemplate(exerciseTemplateId, payload) : createExerciseTemplate(payload)
    }
    const mutationCreateExerciseTemplate = useMutation('createExerciseTemplate', {
        mutationFn: handleMutationProgramTemplate,
        onSuccess: () => {
            const message: string = !!exerciseTemplateId ? t('SaveSuccessful') : t('Created Exercise Template successfully');
            toast.success(message);
            hookForm.reset()
            queryClient.invalidateQueries('getExerciseTemplates', { refetchActive: true }, { cancelRefetch: true });
            queryClient.invalidateQueries('getExerciseTemplateById');
            navigate(routePaths.THERAPIST_EXERCISE_TEMPLATES);

        },
        onError: error => {
            const message: string = messageErrors(error, t);
            toast.error(message);
        },
    });

    const handleSave = async () => {
        const values: FieldValues = hookForm.getValues();
        try {
            let fileId: any = (!exerciseTemplateId || hasVideoUrl || file === null) ? null : exerciseTemplate?.data?.fileId
            if (file?.name) {
                setIsUploadingFile(true)
                const currentFileName = file.name
                  .split('.')
                  .map((item: string) => item.toLowerCase())
                  .join('.');
                const formData = new FormData();
                formData.append('file', file, currentFileName);
                const { data } = await uploadFile({ file: formData });
                setIsUploadingFile(false)
                if (data) fileId = data.id
            }
            const payload = { ...values, name: trim(values.name), fileId }
            mutationCreateExerciseTemplate.mutate(payload)

        } catch (error) {
            setIsUploadingFile(false)
            toast.error(messageErrors(error, t));
        }
    }
    const onSubmit = async () => {
        if (uploading) {
            setOpenAlertUploadingModal(true);
        } else {
            setOpenDiscardSaveModal(false);
            await handleSave();
        }
    }

    const handleBackToExercises = () => {
        setOpenDiscardSaveModal(false);
        dispatch(uploadAction.setUploading(false));
        navigate(routePaths.THERAPIST_EXERCISE_TEMPLATES);
        queryClient.invalidateQueries('getExerciseTemplates', { refetchActive: true }, { cancelRefetch: true });
    }
    const handleBack = () => {
        if (exerciseTemplateId && (isDirty || uploading)) {
            setOpenDiscardSaveModal(true);
        } else {
            dispatch(uploadAction.setUploading(false));
            handleBackToExercises();
        }
    }

    const handleUploadFile = async (file: any) => {
        hookForm.setValue('file', file, { shouldDirty: true });
        const currentFileName = file.name
            ?.split('.')
            .map((item: string) => item.toLowerCase())
            .join('.');
        if (file.size > 500000000) {
            setError(`fileId`, {
                type: 'required',
                message: t('errorSize500MB'),
            })
            setFileUrl(URL.createObjectURL(file));
            file.isUploaded = true;
            setFile(file);
            setDisableVideoUrl(true)
        } else {
            setFileUrl(URL.createObjectURL(file));
            file.isUploaded = true;
            setFile(file);
            setDisableVideoUrl(true)
        }
        if (IMAGE_TYPE_REGEX.test(currentFileName)) {
            setIsVideo(false)
        }
        else {
            setIsVideo(true)
        }
    }
    const handleOnBlur = () => {
        setFileUrl(getValues('videoUrl'))
        setIsVideo(true)
        setHasVideoUrl(true)
    }

    const handleRemoveImage = (e: any) => {
        setFile(null);
        setFileUrl('');
        setDisableVideoUrl(false)
        e.stopPropagation();

    };
    useEffect(() => {
        const videoUrl = hookForm.getValues(`videoUrl`)
        if (!!videoUrl) {
            setHasVideoUrl(true)
            setFileUrl(videoUrl)
            setIsVideo(true)
        } else if (exerciseTemplate?.data?.file) {
            setHasVideoUrl(false)

        } else if (!exerciseTemplate?.data?.file) {
            setFile(null)
            setFileUrl('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hookForm.getValues(`videoUrl`)])

    useEffect(() => {
        if (exerciseTemplate?.data) {
            const initData = {
                name: exerciseTemplate.data.name,
                videoUrl: exerciseTemplate.data.videoUrl,
                description: exerciseTemplate.data.description,
            };
            reset(initData)
            if (exerciseTemplate?.data?.file?.url) {
                if (isVideoType(exerciseTemplate?.data?.file?.type)) {
                    setIsVideo(true)
                } else setIsVideo(false)
                setFile(exerciseTemplate?.data?.file)
                setDisableVideoUrl(true)
                setFileUrl(exerciseTemplate?.data?.file?.url);
            }

            else if (exerciseTemplate?.data?.videoUrl) {
                setHasVideoUrl(true)
                setFileUrl(exerciseTemplate?.data?.videoUrl)
                setIsVideo(true)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exerciseTemplate, exerciseTemplateId])

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="add-exercise-template flex overflow-hidden flex-col h-full">
                <div className="flex h-16 border-b justify-between items-center pl-5 pr-5">
                    <ButtonBack handleBack={handleBack} buttonName={t('back')} />
                    {!isTemplate && (

                        <p className="text-tertiary text-base font-semibold text-header">{!exerciseTemplateId ? t('Add Exercise') : t('Edit Exercise')}</p>
                    )}
                    <Button type="submit" className="bg-primary">{t('save')}</Button>
                </div>
                <div className="form w-full h-full flex p-5">
                    <div className="w-1/3 left-side flex flex-col items-center">
                        <div className="file w-full ">
                            <FileUpload hasVideoUrl={hasVideoUrl} isTemplate hookForm={hookForm} file={file} isVideo={isVideo} handleUploadFile={handleUploadFile} fileUrl={fileUrl} handleRemoveImage={handleRemoveImage} />
                            {errors.fileId && <div className={`w-32 text-red-600 text-xs font-normal mt-1 veri-modal break-words height-50`}>{t('errorSize500MB')}</div>}

                        </div>
                        <div className={`pt-7 h-12 w-full flex flex-col items-center`}>
                            <InputText handleOnBlur={handleOnBlur} hookForm={hookForm} placeholder={t('Video URL')} name="videoUrl" isHolderLabel={true} disabled={disableVideoUrl} />
                        </div>
                    </div>
                    <div className="w-2/3 pl-5 flex flex-col right-side">
                        <div className={`h-12 flex flex-col items-center`}>
                            <InputText hookForm={hookForm} name="name" placeholder={t('name')} isHolderLabel={true} />
                        </div>
                        <div className="grow pt-8 description">
                            <InputText hookForm={hookForm} name="description" type="textarea" placeholder={t('description')} isHolderLabel={true} />
                        </div>
                    </div>
                </div>
            </form >
            {(mutationCreateExerciseTemplate.isLoading || isUploadingFile) && <SpinnerComponent />}
            {openDiscardSaveModal && <DiscardSaveModal openModal={openDiscardSaveModal} setOpenModal={setOpenDiscardSaveModal} onDiscard={handleBackToExercises} onSave={handleSubmit(onSubmit)} />}
            {openAlertUploadingModal && <AlertUploadingModal openModal={openAlertUploadingModal} setOpenModal={setOpenAlertUploadingModal} handleContinue={handleSave} />}
        </>

    )
}
export default AddExerciseTemplate