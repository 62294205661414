/* eslint-disable */

import { Modal } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import CustomModalHeader from './customModalHeader';
import GroupButton from '../button/groupButton';
import _ from 'lodash';
import 'styles/styles.scss';

type changePasswordModalTypes = {
    openModal: boolean,
    setOpenModal: Function,
    closeModal?: Function,
    setIsOrgAlready: Function,
    setEnableCreateOrg: Function
};

const OrgAlreadyModal = (props: changePasswordModalTypes) => {
    const [t] = useTranslation();
    const { openModal, setOpenModal, closeModal, setIsOrgAlready, setEnableCreateOrg } = props;

    const cancelHandler = () => {
        setEnableCreateOrg(true)
        setOpenModal(!openModal);
    };

    const handleSetIsOrgAlready = () => {
        setIsOrgAlready(true),
            setOpenModal(false);
    }

    return (
        <Modal show={openModal} size="xl" popup={true} className="h-full-important modal-change-password">
            <CustomModalHeader title={t('Is your Organization already on Hedge?')} toggle={cancelHandler} />
            <Modal.Body>
                <div className="flex flex-col px-4 gap-4">
                    <GroupButton
                        className="items-center justify-center pt-1 pb-2"
                        buttons={[
                            {
                                type: 'button', 
                                text: t('modal.no'),
                                classType: 'white',
                                action: () => cancelHandler(),
                            },
                            {
                                type: 'button',
                                text: t('modal.yes'),
                                classType: 'blue',
                                action: () => handleSetIsOrgAlready()
                            },
                        ]}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
};
export default OrgAlreadyModal;
