import { useTranslation } from 'react-i18next';
import { Modal } from 'flowbite-react';
import { useRef } from 'react';
import './organizationModal.scss';
import './organizationPatientModal.scss';
import Button from 'components/button/button';
import { themModal } from 'utils/theme';
import GroupButton from 'components/button/groupButton';

const PatientInviteModal = (props: any) => {
  const { openInviteModal, setOpenInviteModal, isReinvite, onSubmit, isLoading } = props;
  const [t] = useTranslation();
  const rootRef = useRef(null);

  const toggleHandler = () => {
    setOpenInviteModal(!openInviteModal);
  };

  return (
    <div ref={rootRef}>
      <Modal
        className="modal-organization-patient"
        show={openInviteModal}
        size="md"
        popup={true}
        root={rootRef.current ?? undefined}
        onClose={toggleHandler}
        dismissible={true}
        theme={themModal}
      >
        <Modal.Body theme={themModal.body}>
          <p className="text-on-primary-container text-base font-semibold text-center">
            {isReinvite ? t('organizationPatients.inviteSent') : t('organizationPatients.useDoesnotExist')}
          </p>
          <p className="text-on-primary-container text-sm text-center pt-2">
            {isReinvite
              ? t('organizationPatients.alreadyInvited')
              : t('organizationPatients.inviteToJoin')
            }
          </p>
          <div className="flex justify-center items-center pt-6">
            <div className="flex justify-center items-center pt-6 px-5 ">
              <GroupButton
                className="items-center justify-center pb-2"
                buttons={[
                  {
                    type: 'button',
                    text: t('modal.cancel'),
                    classType: 'white',
                    action: () => toggleHandler(),
                  },
                  {
                    type: 'button',
                    text: t('modal.invite'),
                    classType: 'blue',
                    action: () => onSubmit(),
                    isLoading: isLoading
                  },
                ]}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default PatientInviteModal;
