import SidebarComponent from './sidebar';
import { propsMainLayoutType } from 'utils/proptypes';
import Header from 'components/header';
import SelectOrganization from 'components/selectOrg';

const MainLayout = (props: propsMainLayoutType) => {
  const { children, type, permissions } = props;
  return (
    <>
      <div className="flex flex-col h-screen">
        <Header type={type} />
        <div className="w-full flex flex-row grow">
          <SidebarComponent type={type} permissions={permissions} />
          <div className="mt-content-mb flex flex-col overflow-y w-full bg-white main-layout-content">
            <div className="selector-organization-mobile z-50">
              <SelectOrganization type={type} />
            </div>
            <div className="grow">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MainLayout;
