// eslint-disable-next-line
import base from './baseApi';
import { omitBy } from 'lodash';
import { createSearchParams } from 'react-router-dom';

const { urls, methods, execute } = base();

const getOrganizationExerciseTemplates = (queryFilter: any) => {
  const method = methods.GET;
  const initParams: any = omitBy(queryFilter, v => v === '');
  const params = createSearchParams(initParams);

  const url = `${urls.organizationExerciseTemplate.organizationExerciseTemplate}?${params.toString()}`;
  const response = execute(method, url);

  return response;
};

const getOrganizationExerciseTemplateById = (exerciseTemplateId: any) => {
  const method = methods.GET;

  const url = `${urls.organizationExerciseTemplate.organizationExerciseTemplate}/${exerciseTemplateId}`;
  const response = execute(method, url);

  return response;
}

const createOrganizationExerciseTemplate = (payload: any) => {
  const method = methods.POST;
  const data: any = omitBy(payload, v => v === '');

  const url = `${urls.organizationExerciseTemplate.organizationExerciseTemplate}`;
  const response = execute(method, url, data);

  return response;
};

const deleteOrganizationExerciseTemplate = (id: any) => {
  const method = methods.DELETE;
  const url = `${urls.organizationExerciseTemplate.organizationExerciseTemplate}/${id}`;
  const response = execute(method, url);

  return response;
};

const updateOrganizationExerciseTemplate = (exerciseTemplateId: string, payload: any) => {
  const method = methods.PUT;
  const url = `${urls.organizationExerciseTemplate.organizationExerciseTemplate}/${exerciseTemplateId}`;
  const response = execute(method, url, payload);

  return response;
};

export {
  getOrganizationExerciseTemplates,
  createOrganizationExerciseTemplate,
  deleteOrganizationExerciseTemplate,
  getOrganizationExerciseTemplateById,
  updateOrganizationExerciseTemplate
};
