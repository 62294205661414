// eslint-disable-next-line
export default {
  baseUrl: `${process.env.REACT_APP_BASE_API_URL}/v1`,
  user: {
    users: 'Users',
    userProfile: 'users/profile',
    getCurrentUser: 'Users/CurrentUser',
    siteUsers: 'site-users',
    organizationUsers: 'organization-users',
    userControllerChangeEmailAddress: 'Users/ChangeEmailAddress',
    setRoles: 'Users/SetRoles',
    resetPassword: 'Users/reset-password',
    resendEmailVerification: 'Users/OAuth/ResendEmailVerification',
    userEmail: 'users/email',
    intercomUser: 'intercom/user',
    intercomGenerateHmac: 'intercom/generate-user-hash'
  },

  role: {
    Roles: 'roles',
    adminRoles: 'roles/adminRoles',
    organizationRoles: 'roles/organizationRoles',
  },

  conversation: {
    conversations: 'conversations',
  },
  message: {
    messages: 'messages',
    viewAll: 'messages/view'
  },

  organization: {
    organizations: 'organizations',
    filter: 'Organizations/Filter',
  },

  userOrganization: {
    userOrganizations: 'UserOrganizations',
  },

  myTokens: {
    myTokens: 'authentication',
  },

  health: 'Health',

  plans: 'plans',
  subscriptions: 'subscriptions',
  stripes: {
    stripes: 'stripes',
    confirm: 'stripes/confirm',
    chargeSubscription: 'stripes/chargeSubscription',
    createOrgAdminSubscription: 'stripes/createOrgAdminSubscription',
    customerPortal: 'stripes/customer-portal',
    getSubscription: 'stripes/getSubscription'
  },
  programs: {
    programs: '/programs'
  },
  exerciseTemplate: {
    exerciseTemplate: '/exercise-template'
  },
  organizationExerciseTemplate: {
    organizationExerciseTemplate: '/organization-exercise-template'
  },
  files: {
    files: '/files',
    copyFile: '/files/copy-file'
  },
  programTemplates: {
    programTemplates: '/program-template',
    programTemplatesExercise: '/program-template-exercise'
  },
  exercises: {
    exercises: '/exercises',
    exercisesChart: '/exercises/exercises-chart',
    exerciseCompletion: '/exercise-completion',
    uploadFile: '/exercises/uploadFile'
  },
  exerciseTemplates: {
    exerciseTemplates: '/exercise-template',
  },
  intercom: {
    contact: '/intercom/contact',
    generateHmac: '/intercom/generate-hmac',
  },
  siteExerciseTemplates: {
    siteExerciseTemplates: '/site-exercise-template',
  },
};
