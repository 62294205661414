
import PaginateTable from 'components/table/paginate';
import { HiOutlinePencil, HiOutlineTrash } from 'react-icons/hi';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { apiStatus, ExerciseLibraryType, pageCount, routePaths } from 'utils/constants';
import { isEmpty } from 'lodash';
import NoResult from 'components/commonComponent/noResult';
import { useSelector } from 'react-redux';
import ActionTable from 'components/table/actionTable';
import { orgSelector } from 'redux/selectors';
import Table from 'components/table/table';
import { useNavigate } from 'react-router-dom';
import Actions from 'components/actions';
import SpinnerComponent from 'components/spinner';
import ListEmpty from 'components/empty/ListEmpty';
import Svg from 'components/svg';
import { toast } from 'react-toastify';
import { deleteExerciseTemplate, getExerciseTemplates } from 'api/exerciseTemplateApi';
import { isImageType, isVideoType } from 'utils/utils';
import ReactPlayer from "react-player";
import OpenFileModal from '../patient/openFileModal';
import { useForm } from 'react-hook-form';
import { getOrganizationExerciseTemplates } from '../../../api/organizationExerciseTemplateApi';
import { getSiteExerciseTemplates } from '../../../api/siteExerciseTemplateApi';

const ExerciseTemplateList = (props: any) => {
    const queryClient = useQueryClient();
    const [t] = useTranslation();
    const navigate = useNavigate();
    const { organizationId } = useSelector(orgSelector);
    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [currentPageOrganization, setCurrentPageOrganization] = useState(0);
    const [currentPageSite, setCurrentPageSite] = useState(0);
    const [totalEntities, setTotalEntities] = useState(0);
    const [exerciseTemplates, setExerciseTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState<any>(null)
    const [openFile, setOpenFile] = useState(false)
    const [tab, setTab] = useState(ExerciseLibraryType.MY_EXERCISE);

    const hookForm = useForm({
        mode: "onChange",
        // resolver: yupResolver(ValidateSchema),
        defaultValues: {
            exercises: [selectedTemplate]
        }
    });
    const {
        formState: { errors },
        reset
    } = hookForm

    const handleAddExerciseTemplate = () => {
        navigate(routePaths.ADD_EXERCISE_TEMPLATE)
    };

    const { data, isLoading, isFetching } = useQuery(
        ['getExerciseTemplates', currentPage, searchValue, organizationId],
        () =>
            getExerciseTemplates({
                page: currentPage,
                limit: pageCount,
                searchQuery: searchValue,
            }),
        {
            onSuccess: (data: any) => {
                if(tab === ExerciseLibraryType.MY_EXERCISE) {
                  setTotalEntities(data.totalEntities);
                }
            },
            onError: () => setExerciseTemplates([]),
            staleTime: Infinity,
        },
    );

    const { data: organizationExerciseTemplatesData, isLoading: isLoadingOrganization } = useQuery(
      ['getOrganizationExerciseTemplates', currentPageOrganization, searchValue, organizationId],
      () =>
        getOrganizationExerciseTemplates({
            page: currentPageOrganization,
            limit: pageCount,
            searchQuery: searchValue,
        }),
      {
          onSuccess: (data: any) => {
            if(tab === ExerciseLibraryType.ORGANIZATION_EXERCISE) {
              setTotalEntities(data.totalEntities);
            }
          },
          onError: () => setExerciseTemplates([]),
          staleTime: Infinity,
      },
    );

    const { data: siteExerciseTemplatesData, isLoading: isLoadingSite } = useQuery(
      ['getSiteExerciseTemplates', currentPageSite, searchValue, organizationId],
      () =>
        getSiteExerciseTemplates({
            page: currentPageSite,
            limit: pageCount,
            searchQuery: searchValue,
        }),
      {
          onSuccess: (data: any) => {
            if(tab === ExerciseLibraryType.SITE_EXERCISE) {
              setTotalEntities(data.totalEntities);
            }
          },
          onError: () => setExerciseTemplates([]),
          staleTime: Infinity,
      },
    );

    const updateExerciseTemplates = (newTab: any) => {
        if (newTab === ExerciseLibraryType.MY_EXERCISE) {
            setExerciseTemplates(data?.data?.entities);
            setTotalEntities(data.totalEntities);
        }

        if (newTab === ExerciseLibraryType.ORGANIZATION_EXERCISE) {
            setExerciseTemplates(organizationExerciseTemplatesData?.data?.entities);
            setTotalEntities(organizationExerciseTemplatesData.totalEntities);
        }

        if (newTab === ExerciseLibraryType.SITE_EXERCISE) {
            setExerciseTemplates(siteExerciseTemplatesData?.data?.entities);
            setTotalEntities(siteExerciseTemplatesData.totalEntities);
        }
    };

    const handleChangeTab = (newTab: any) => {
        setTab(newTab);
        setCurrentPage(0);
        setCurrentPageOrganization(0);
        setCurrentPageSite(0);
        updateExerciseTemplates(newTab);
    };

    const handleSearch = (value: string) => {
        setSearchValue(value);
        setCurrentPage(0);
        setCurrentPageOrganization(0);
        setCurrentPageSite(0);
        setTimeout(() => {
            updateExerciseTemplates(tab);
        },3000);

    };

    const handleEditExerciseTemplate = (exerciseTemplateId: string) => {
        navigate(`${routePaths.ADD_EXERCISE_TEMPLATE}?exerciseTemplateId=${exerciseTemplateId}`);
    }

    const handleRemoveExerciseTemplate = async (exerciseTemplateId: string) => {
        try {
            const data = await deleteExerciseTemplate(exerciseTemplateId);
            if (data.status === apiStatus.SUCCESS) {
                const message: string = t('Deleted Exercise Template successfully');
                toast.success(message);
                queryClient.invalidateQueries('getExerciseTemplates', { refetchActive: true }, { cancelRefetch: true });
            }
        } catch (e) {
            const message: string = t('generalErrors');
            toast.error(message);
        }
    }

    const handleViewExercise = (item: any) => {
        setOpenFile(true)
        setSelectedTemplate(item)
        reset(item)
    }

    useEffect(() => {
        if (tab === ExerciseLibraryType.MY_EXERCISE) {
            setExerciseTemplates(data?.data?.entities);
            setTotalEntities(data?.data.totalEntities);
        }
        if (tab === ExerciseLibraryType.ORGANIZATION_EXERCISE) {
            setExerciseTemplates(organizationExerciseTemplatesData?.data?.entities);
            setTotalEntities(organizationExerciseTemplatesData?.data.totalEntities);
        }
        if (tab === ExerciseLibraryType.SITE_EXERCISE) {
            setExerciseTemplates(siteExerciseTemplatesData?.data?.entities);
            setTotalEntities(siteExerciseTemplatesData?.data.totalEntities);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, organizationExerciseTemplatesData, siteExerciseTemplatesData, tab]);

    useEffect(() => {
        setCurrentPage(0);
        setCurrentPageOrganization(0);
        setCurrentPageSite(0);
        setTab(ExerciseLibraryType.MY_EXERCISE)
    }, [organizationId])

    useEffect(() => {
        setCurrentPage(0);
        setCurrentPageOrganization(0);
        setCurrentPageSite(0);
        setTab(ExerciseLibraryType.MY_EXERCISE)
    }, [])

    if (((tab === ExerciseLibraryType.MY_EXERCISE &&  !isLoading) ||
      (tab === ExerciseLibraryType.ORGANIZATION_EXERCISE && !isLoadingOrganization) ||
      (tab === ExerciseLibraryType.SITE_EXERCISE && !isLoadingSite)) &&
      isEmpty(searchValue) &&
      totalEntities === 0) {
        return (
            <div className="w-full sm:p-8 p-4">
                <ListEmpty
                    icon={
                        <Svg name='exercise-template-empty' className='h-[84px] w-auto' />
                    }
                    buttonName={t('Add Exercise')}
                    handleAddClick={handleAddExerciseTemplate}
                    note={t('Please ADD a Exercise Library and it will show up here')}
                    title={t('This Therapist does not have any Exercise Library yet')}
                />
            </div>
        );
    }

    const renderCustomTabs = () => {
      return (
        <div className="flex h-34 mr-5">
          <div
            onClick={() => handleChangeTab(ExerciseLibraryType.MY_EXERCISE)}
            className={`px-3 py-1 border-secondary cursor-pointer border rounded-l-md ${
              tab === ExerciseLibraryType.MY_EXERCISE ? 'bg-secondary text-white' : ''
            }`}
          >
            {t('My Library')}
          </div>
          <div
            onClick={() => handleChangeTab(ExerciseLibraryType.ORGANIZATION_EXERCISE)}
            className={`px-3 py-1 border-secondary cursor-pointer border border-l-0 border-r-0 ${
              tab === ExerciseLibraryType.ORGANIZATION_EXERCISE ? 'bg-secondary text-white' : ''
            }`}
          >
            {t('Organization Library')}
          </div>
          <div
            onClick={() => handleChangeTab(ExerciseLibraryType.SITE_EXERCISE)}
            className={`px-3 py-1 border-secondary cursor-pointer border rounded-r-md ${
              tab === ExerciseLibraryType.SITE_EXERCISE ? 'bg-secondary text-white' : ''
            }`}
          >
            {t('Hedge Library')}
          </div>
        </div>
      );
    };

    return (
      <div className="w-full h-full sm:px-8 px-4">
        <div className="md:py-5">
          <ActionTable
            placeholderSearch={t('Search')}
            buttonName={t('Add Exercise')}
            handleAddClick={tab === ExerciseLibraryType.MY_EXERCISE && handleAddExerciseTemplate}
            handleSearch={handleSearch}
            renderCustomTabs={renderCustomTabs}
          />
        </div>
        {((tab === ExerciseLibraryType.MY_EXERCISE &&  isLoading) ||
          (tab === ExerciseLibraryType.ORGANIZATION_EXERCISE && isLoadingOrganization) ||
          (tab === ExerciseLibraryType.SITE_EXERCISE && isLoadingSite)) && <SpinnerComponent />}
        {totalEntities === 0 && ((tab === ExerciseLibraryType.MY_EXERCISE &&  !isLoading) ||
          (tab === ExerciseLibraryType.ORGANIZATION_EXERCISE && !isLoadingOrganization) ||
          (tab === ExerciseLibraryType.SITE_EXERCISE && !isLoadingSite)) && <NoResult />}
        {totalEntities > 0 && (
          <>
            <div className="overflow-x-auto mb-2 list-table">
              <Table>
                <Table.Head>
                  <Table.HeadCell className="cursor-pointer">
                    <div className="flex items-center">{t('Exercise Name')}</div>
                  </Table.HeadCell>
                  <Table.HeadCell className="hidden-mobile">
                    <div className="flex items-center">{t('Video/Image')}</div>
                  </Table.HeadCell>
                  <Table.HeadCell className="hidden-mobile-tablet">
                    <div className="flex items-center">{t('Description/Tips')}</div>
                  </Table.HeadCell>
                  <Table.HeadCell scope="col" className="py-2 w-6" />
                </Table.Head>
                <Table.Body>
                  {exerciseTemplates?.map((item: any) => (
                    <Table.Row key={`row-${item.id}`} className="bg-white hover:bg-bg-hover text-base hover:border-b text-on-primary-container">
                      <Table.Cell className="text-sm py-2.5 " onClick={() => tab === ExerciseLibraryType.MY_EXERCISE ? handleEditExerciseTemplate(item?.id) : handleViewExercise(item)}>
                        {item.name}
                      </Table.Cell>
                      <Table.Cell className="text-sm hidden-mobile py-2.5 " onClick={() => handleViewExercise(item)}>
                        {(item?.videoUrl || (item?.file?.url && isVideoType(item?.file?.type))) && (
                          <div className="relative w-full rounded-lg bg-black" style={{ width: 80, maxWidth: 80, maxHeight: 60 }}>
                            {item?.videoUrl ? (
                              <span className="absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center focus:outline-none focus:ring-4 focus:ring-gray-200">
                                <Svg name="youtube-play-icon" className="w-[25px] h-[25px]" />
                              </span>
                            ) : (
                              <span className="absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center focus:outline-none focus:ring-4 focus:ring-gray-200">
                                <Svg name="play-icon" className="w-[25px] h-25px]" />
                              </span>
                            )}
                            {item?.file?.thumbnail && (
                              <div className="rounded-lg border border-gray-300 relative" style={{ width: 80, height: 60 }}>
                                <span className="absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center focus:outline-none focus:ring-4 focus:ring-gray-200">
                                  <Svg name="play-icon" className="w-[25px] h-25px]" />
                                </span>
                                <img
                                  className="preview object-cover rounded"
                                  style={{ width: 80, height: 60 }}
                                  src={item?.file?.thumbnail}
                                  alt="avatar"
                                />
                              </div>
                            )}
                            {!item?.file?.thumbnail && (
                              <ReactPlayer
                                controls={false}
                                playIcon={<button className="hidden">{''}</button>}
                                playing={false}
                                className="react-player rounded-lg"
                                width={80}
                                height={60}
                                url={item?.videoUrl ? item?.videoUrl : item?.file?.url}
                                light={!!item?.videoUrl}
                                style={{ borderRadius: 8 }}
                              />
                            )}
                          </div>
                        )}

                        {item?.file?.url && isImageType(item?.file?.type) && (
                          <div className="rounded-lg border border-gray-300 relative" style={{ width: 80, height: 60 }}>
                            <img className="preview object-cover rounded" style={{ width: 80, height: 60 }} src={item?.file?.url} alt="avatar" />
                          </div>
                        )}
                        {!item?.file?.url && !item?.videoUrl && (
                          <div className="rounded-lg flex justify-center items-center border relative bg-[#EDEDED]" style={{ width: 80, height: 60 }}>
                            <Svg name="empty-image" className="w-[25px] h-25px]" />
                          </div>
                        )}
                      </Table.Cell>
                      <Table.Cell className="text-sm hidden-mobile-tablet py-2.5 whitespace-pre-line">{item.description}</Table.Cell>
                      <Table.Cell>
                          { tab === ExerciseLibraryType.MY_EXERCISE && <Actions>
                          <Actions.Item
                            show
                            action={() => handleEditExerciseTemplate(item?.id)}
                            icon={<HiOutlinePencil className="w-5 h-5  text-on-primary-container" strokeWidth={2.5} />}
                          />
                          <Actions.Item
                            show
                            action={() => handleRemoveExerciseTemplate(item?.id)}
                            icon={<HiOutlineTrash className="w-5 h-5  text-on-primary-container" strokeWidth={2.5} />}
                          />
                        </Actions>}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
            {tab === ExerciseLibraryType.MY_EXERCISE && <PaginateTable setCurrentPage={setCurrentPage} currentPage={currentPage} totalEntities={totalEntities} />}
            {tab === ExerciseLibraryType.ORGANIZATION_EXERCISE && <PaginateTable setCurrentPage={setCurrentPageOrganization} currentPage={currentPageOrganization} totalEntities={totalEntities} />}
            {tab === ExerciseLibraryType.SITE_EXERCISE && <PaginateTable setCurrentPage={setCurrentPageSite} currentPage={currentPageSite} totalEntities={totalEntities} />}
            <OpenFileModal
              isTemplate
              hookForm={hookForm}
              hasVideoUrl={!!selectedTemplate?.videoUrl}
              isVideo={isVideoType(selectedTemplate?.file?.type) || !!selectedTemplate?.videoUrl}
              fileUrl={selectedTemplate?.file?.url || selectedTemplate?.videoUrl}
              openModal={openFile}
              setOpenModal={setOpenFile}
            />
          </>
        )}
      </div>
    );
};

export default ExerciseTemplateList;
