// eslint-disable-next-line
import base from './baseApi';
import { omitBy } from 'lodash';
import { createSearchParams } from 'react-router-dom';

const { urls, methods, execute } = base();
const getSiteExerciseTemplates = (queryFilter: any) => {
  const method = methods.GET;
  const initParams: any = omitBy(queryFilter, v => v === '');
  const params = createSearchParams(initParams);

  const url = `${urls.siteExerciseTemplates.siteExerciseTemplates}/?${params.toString()}`;
  const response = execute(method, url);

  return response;
};

const getSiteExerciseTemplateById = (exerciseTemplateId: any) => {
  const method = methods.GET;

  const url = `${urls.siteExerciseTemplates.siteExerciseTemplates}/${exerciseTemplateId}`;
  const response = execute(method, url);

  return response;
}


const createSiteExerciseTemplate = (payload: any) => {
  const method = methods.POST;
  const data: any = omitBy(payload, v => v === '');

  const url = `${urls.siteExerciseTemplates.siteExerciseTemplates}`;
  const response = execute(method, url, data);

  return response;
};
const deleteSiteExerciseTemplate = (id: any) => {
  const method = methods.DELETE;
  const url = `${urls.siteExerciseTemplates.siteExerciseTemplates}/${id}`;
  const response = execute(method, url);

  return response;
};
const updateSiteExerciseTemplate = (exerciseTemplateId: string, payload: any) => {
  const method = methods.PUT;
  const url = `${urls.siteExerciseTemplates.siteExerciseTemplates}/${exerciseTemplateId}`;
  const response = execute(method, url, payload);

  return response;
};

export {
  getSiteExerciseTemplates,
  createSiteExerciseTemplate,
  deleteSiteExerciseTemplate,
  getSiteExerciseTemplateById,
  updateSiteExerciseTemplate
};
