/* eslint-disable react/jsx-props-no-spreading */
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'flowbite-react';
import { themModal } from 'utils/theme';
import CustomModalHeader from 'components/modal/customModalHeader';
import './openFileModal.scss'
import ReactPlayer from 'react-player'
import Svg from 'components/svg';
import { isSafari } from '../../../utils/utils';

const OpenFileModal = (props: any) => {
    const { openModal, setOpenModal, isVideo, type, roles, hookForm, fileUrl, index, isTemplate, hasVideoUrl } = props;
    const [t] = useTranslation();
    const [isPlaying, setIsPlaying] = useState(false)

    const rootRef = useRef(null);

    const handleClose = () => {
        setOpenModal(false);
    };

    const { getValues } = hookForm

    return (
        <div ref={rootRef}>
            <Modal
                className="modal-video"
                show={openModal}
                size="3xl"
                root={rootRef.current ?? undefined}
                onClose={() => handleClose()}
                dismissible={true}
                theme={themModal}
                position="top-center"
            >
                <CustomModalHeader title={isTemplate ? getValues('name') : getValues('exercises')[index].name} toggle={() => handleClose()} />
                <Modal.Body className='modal-body' theme={{ base: 'flex-1 pb-6 pt-3 px-12 overflow-y-auto' }}>
                    <div className='flex justify-center w-full'>
                        {isVideo ? (
                            <div className='w-full aspect-video'>
                                 {!isSafari() && !hasVideoUrl && !isPlaying &&  <span className="absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center focus:outline-none focus:ring-4 focus:ring-gray-200">
                                    <Svg name='play-icon' className='w-[60px] h-[60px]' />
                                </span>}
                                <ReactPlayer
                                    width="100%"
                                    height="100%"
                                    playIcon={hasVideoUrl && <button className='hidden'>{''}</button>}
                                    onPlay={() => setIsPlaying(true)}
                                    onPause={() => setIsPlaying(false)}
                                    controls
                                    url={fileUrl}
                                />
                            </div>

                        ) : (
                            <div className='w-full aspect-video'>
                                <img className="preview w-full h-full object-cover rounded" src={fileUrl} alt="avatar" />
                            </div>
                        )}
                    </div>
                    <div className='flex mt-3 justify-center'>
                        <a className='cursor-pointer underline text-tertiary underline-offset-2' href={isTemplate ? getValues('videoUrl') : hookForm.getValues(`exercises[${index}].videoUrl`)} rel="noopener noreferrer" target="_blank">
                            {isTemplate ? getValues('videoUrl') : hookForm.getValues(`exercises[${index}].videoUrl`)}
                        </a>
                    </div>
                    <div className='mt-3'>{isTemplate ? getValues('description') : getValues('exercises')[index].description}</div>
                </Modal.Body>
            </Modal>


        </div>
    );
};
export default OpenFileModal;
