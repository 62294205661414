import { OrganizationList, UsersPage, VersionPage, ContactSupportPage, LoginPage, RoleManagementPage } from 'pages';
import EmailVerification from 'pages/emailVerification';
import MyTokens from 'pages/myTokens/myTokensManagement';
import NotFoundPage from 'pages/notFound';
import { routePaths, permissionKeys, pageType } from 'utils/constants';
import { routeType } from 'utils/proptypes';
import Subscription from '../pages/subscription/subscription';
import MySubscription from 'pages/mySubscriptions/mySubscriptions';
import StripePayment from 'pages/stripePayment/stripePayment';
import OrganizationDetail from 'pages/organization/organizationDetail';
import UserDetail from 'pages/user/userDetail';
import OrganizationTherapists from 'pages/organization/organizationTherapists';
import OrganizationPatients from 'pages/organization/organizationPatients';
import MyMessages from 'pages/messages/myMessages';
import PatientListPage from 'pages/therapists/patient/patientList';
import PatientProfile from 'pages/therapists/patient/patientProfile';
import TherapistDetail from 'pages/organization/organizationTherapistDetail';
import ProgramTemplateList from 'pages/therapists/program-template/programTemplate';
import PatientPrograms from '../pages/patients/patientPrograms';
import PatientDashboard from 'pages/patients/dashboard';
import PatientProgramsDetail from '../pages/patients/patientProgramExercises';
import ExerciseTemplateList from 'pages/therapists/exercise-template/exerciseTemplate';
import AddExerciseTemplate from 'pages/therapists/exercise-template/addExerciseTemplate';
import PatientDetail from 'pages/organization/organizationPatientDetail';
import ProgramDetail from 'pages/therapists/patient/programDetail';
import ProgramTemplateDetail from 'pages/therapists/program-template/programTemplateDetail';
import ChangePasswordPage from 'pages/authen/ChangePassword';
import SiteExerciseTemplateList from '../pages/site-exercise-template/siteExerciseTemplate';
import AddSiteExerciseTemplate from '../pages/site-exercise-template/addSiteExerciseTemplate';
import OrganizationExerciseTemplate from '../pages/organization-exercise-template/organizationExerciseTemplate';
import AddOrganizationExerciseTemplate from '../pages/organization-exercise-template/addOrganizationExerciseTemplate';

const { READ_USER, WRITE_USER, READ_ORG, WRITE_ORG } = permissionKeys;
const { READ_ROLE, WRITE_ROLE, READ_AUTHEN, WRITE_AUTHEN } = permissionKeys;
const { SITE, ORGANIZATION, THERAPIST, PATIENT } = pageType;
const isUseStripe = process.env.REACT_APP_IS_USE_STRIPE === 'true';
const isUseSubscription = process.env.REACT_APP_IS_USE_SUBSCRIPTION === 'true';

const routes: routeType[] = [
  { path: routePaths.VERSION_PAGE, component: VersionPage },
  { path: routePaths.CONTACT_SUPPORT, component: ContactSupportPage },
  { path: routePaths.ORGANIZATION_PAGE, component: OrganizationList, layout: 'MainLayout', permissions: [READ_ORG, WRITE_ORG], type: SITE },
  { path: routePaths.NOT_FOUND_PAGE, component: NotFoundPage },
  { path: routePaths.CHANGE_PASSWORD, component: ChangePasswordPage },
  { path: routePaths.ADMIN_USERS_PAGE, component: UsersPage, layout: 'MainLayout', permissions: [READ_USER, WRITE_USER], type: SITE },
  { path: routePaths.ROLES_PAGE, component: RoleManagementPage, layout: 'MainLayout', permissions: [READ_ROLE, WRITE_ROLE], type: SITE },
  { path: routePaths.EMAIL_VERIFICATION, component: EmailVerification },
  { path: routePaths.MY_TOKENS, component: MyTokens, layout: 'MainLayout', permissions: [READ_AUTHEN, WRITE_AUTHEN], type: SITE },
  { path: routePaths.ORG_OVERVIEW_PAGE, component: OrganizationDetail, layout: 'MainLayout', permissions: [READ_ORG, WRITE_ORG], type: ORGANIZATION },
  { path: routePaths.ADMIN_USER_DETAIL, component: UserDetail, layout: 'MainLayout', type: SITE },
  { path: routePaths.ORG_USER_DETAIL, component: UserDetail, layout: 'MainLayout', type: ORGANIZATION },
  { path: routePaths.THERAPIST_PATIENTS, component: PatientListPage, layout: 'MainLayout', type: THERAPIST },
  { path: routePaths.THERAPIST_MESSAGES, component: MyMessages, layout: 'MainLayout', type: THERAPIST },
  { path: routePaths.THERAPIST_MESSAGE_DETAIL, component: MyMessages, layout: 'MainLayout', type: THERAPIST },
  { path: routePaths.ORG_THERAPISTS, component: OrganizationTherapists, layout: 'MainLayout', type: ORGANIZATION },
  { path: routePaths.ORG_PATIENTS, component: OrganizationPatients, layout: 'MainLayout', type: ORGANIZATION },
  { path: routePaths.ORG_PATIENT_DETAIL, component: PatientDetail, layout: 'MainLayout', type: ORGANIZATION },
  { path: routePaths.THERAPIST_PATIENTS, component: PatientListPage, layout: 'MainLayout', type: THERAPIST },
  { path: routePaths.THERAPIST_PATIENTS_DETAIL, component: PatientProfile, layout: 'MainLayout', type: THERAPIST },
  { path: routePaths.ORG_THERAPIST_DETAIL, component: TherapistDetail, layout: 'MainLayout', type: ORGANIZATION },
  { path: routePaths.PROGRAM_DETAIL, component: ProgramDetail, layout: 'MainLayout', type: THERAPIST },
  { path: routePaths.PATIENT_MESSAGES, component: MyMessages, layout: 'MainLayout', type: PATIENT },
  { path: routePaths.PATIENT_DASHBOARD, component: PatientDashboard, layout: 'MainLayout', type: PATIENT },
  { path: routePaths.PATIENT_MESSAGE_DETAIL, component: MyMessages, layout: 'MainLayout', type: PATIENT },
  { path: routePaths.THERAPIST_PROGRAM_TEMPLATES, component: ProgramTemplateList, layout: 'MainLayout', type: THERAPIST },
  { path: routePaths.PROGRAM_TEMPLATE_DETAIL, component: ProgramTemplateDetail, layout: 'MainLayout', type: THERAPIST },
  { path: routePaths.THERAPIST_EXERCISE_TEMPLATES, component: ExerciseTemplateList, layout: 'MainLayout', type: THERAPIST },
  { path: routePaths.ADD_EXERCISE_TEMPLATE, component: AddExerciseTemplate, layout: 'MainLayout', type: THERAPIST },
  { path: routePaths.SITE_EXERCISE_TEMPLATES_PAGE, component: SiteExerciseTemplateList, layout: 'MainLayout', type: SITE },
  { path: routePaths.ADD_SITE_EXERCISE_TEMPLATES_PAGE, component: AddSiteExerciseTemplate, layout: 'MainLayout', type: SITE },
  { path: routePaths.ORG_EXERCISE_TEMPLATES, component: OrganizationExerciseTemplate, layout: 'MainLayout', type: ORGANIZATION },
  { path: routePaths.ORG_ADD_EXERCISE_TEMPLATES, component: AddOrganizationExerciseTemplate, layout: 'MainLayout', type: ORGANIZATION },

  { ...(isUseSubscription ? { path: routePaths.SUBSCRIPTION, component: Subscription } : {}) },
  { ...(isUseSubscription ? { path: routePaths.MY_SUBSCRIPTIONS, component: MySubscription, layout: 'MainLayout', permissions: [] } : {}) },
  { ...(isUseStripe ? { path: routePaths.STRIPE_PAYMENT, component: StripePayment, layout: 'UserLayout' } : {}) },
  { path: routePaths.PATIENT_PROGRAM, component: PatientPrograms, layout: 'MainLayout', type: PATIENT },
  { path: routePaths.PATIENT_PROGRAM_EXERCISES, component: PatientProgramsDetail, layout: 'MainLayout', type: PATIENT },
];

export default routes;
